import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class MaterialRequisitionAllocatadeRequisitionPsmEvents extends Event {
    
    static async converterData(dataAmericana: string) {
        console.log("dataAmericana :>", dataAmericana)
        if (dataAmericana && dataAmericana.length > 8) {

            const partes = dataAmericana?.split('-');
            const splitData = partes[2]?.split('T');

            const mes = partes[1];
            const dia = splitData[0];
            const ano = partes[0];
            const dataBrasileira = `${dia}/${mes}/${ano}`;
            console.log("dataBrasileira :>", dataBrasileira)
            return dataBrasileira;
        }
        if (dataAmericana && dataAmericana.length == 8) {

            const mes = dataAmericana.substring(4, 6);
            const dia = dataAmericana.substring(6, 8);
            const ano = dataAmericana.substring(0, 4);
            const dataBrasileira = `${dia}/${mes}/${ano}`;
            return dataBrasileira;
        }
    }

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        let isTypePsm = value[0].psm_os_number.substring(0, 2);
        isTypePsm = isTypePsm.substring(1);
        const isCrud = isTypePsm == 'L' ? 'commercials' : 'dibi';
        const isSelectSituationPsm = isTypePsm == 'L' ? 'situation' : 'dibi_situation';
        // console.log("Valor do value", value)
        // console.log("Valor do this.globalProps", this.globalProps)
        //LL09380CB
        if (value[0].psm_os_number === this.globalProps.values.material_requisition_number_psm_os_number || this.globalProps.values.material_requisition_number_psm_os_number  === undefined ){
            axios.get(`/api/v1/material/generate-code/` + value[0][`psm_fk_company_id`])
            .then((res) => {
                this.setFieldValue('material_requisition_os_number', res.data.code);
            })
        
        this.setState('loadings', [
            'material_requisition_number_psm_os_number',
            'material_requisition_fk_business_unity_id',
            'material_requisition_fk_person_id',
            'material_requisition_psm_dibi_situation',
            'material_requisition_comercial_status',
            'material_requisition_purpose_product',
            'material_requisition_priority',
            'material_requisition_deadline',
            'material_requisition_logistics',
            'material_requisition_requisition_date',
            'material_requisition_fk_company_id',
            'material_requisition_fk_situation_id',
            'material_requisition_fk_dibi_situation_id',
            'material_requisition_manual_creation',
            'material_requisition_liberation',
            'material_requisition_fk_sub_situation_id'
        ])
        const newObject = [];
        for (const i in value) {
            let isValidType = false;
            for (const t in value[i]) {
                if (t.includes('_relation_product')) {
                    isValidType = true;
                    continue;
                }
            }
            const isTypeTableProduct = isValidType ? 'product' : 'service';
            newObject.push({
                material_requisition_logistic_iten_rm: Number(i) + 1,
                material_requisition_logistic_registered: value[i][`psm_relation_${isTypeTableProduct}_registered`],
                material_requisition_logistic_installed: value[i][`psm_relation_${isTypeTableProduct}_instal`],
                material_requisition_logistic_intern_reference: value[i][`psm_relation_${isTypeTableProduct}_intern_reference`],
                material_requisition_logistic_item_description: value[i][`psm_relation_${isTypeTableProduct}_item_description`],
                material_requisition_logistic_manufacture_code: value[i][`psm_relation_${isTypeTableProduct}_manufacture_code`],
                material_requisition_logistic_total_quantity: value[i][`psm_relation_${isTypeTableProduct}_quantity`],
                material_requisition_logistic_table_product_id: value[i][`psm_relation_${isTypeTableProduct}_id`],
                material_requisition_logistic_product_table_name: `psm_relation_${isTypeTableProduct}s`,
                material_requisition_logistic_observation: value[i][`psm_relation_${isTypeTableProduct}_obs`],
                material_requisition_logistic_fk_select_id: value[i][`psm_relation_${isTypeTableProduct}_fk_select_id`],
                material_requisition_logistic_psm_id: value[0][`psm_id`],
                material_requisition_logistic_quantity_to_separate: value[i][`psm_relation_${isTypeTableProduct}_quantity`]
            })
            // console.log ("Eu to aqui 1 ::::::::",  value[i][`psm_relation_product_id`]);
            // console.log ("Eu to aqui 2 ::::::::", value[i]);
        }
        this.setFieldValue('material_requisition_logistics', newObject)
        this.setField('material_requisition_manual_creation', {
            disabled: true
        })

        axios.get(`/api/v1/${isCrud}/` + value[0]['psm_id'])
            .then(async (res) => {
                const itens = res.data.item;
                // console.log("irm herehre::>", {itens});                
                const getNewDate = await MaterialRequisitionAllocatadeRequisitionPsmEvents.converterData(itens.psm_final_date_delivery)

                console.log("getNewDate :.", getNewDate)

                this.setFieldValue('material_requisition_number_psm_os_number', itens.psm_os_number);
                this.setFieldValue('material_requisition_fk_business_unity_id', itens.psm_fk_business_unity_id);
                this.setFieldValue('material_requisition_fk_person_id', itens.psm_clients_fk_person_id);
                if (itens.psm_comercial_status !== null) {
                    this.setFieldValue('material_requisition_comercial_status', itens.psm_comercial_status);
                }
                if (itens.psm_purpose_product !== null) {
                    this.setFieldValue('material_requisition_purpose_product', itens.psm_purpose_product);
                }
                if (itens?.psm_liberation !== null) {
                    this.setFieldValue('material_requisition_liberation', parseInt(itens.psm_liberation))
                }
                if (itens.psm_sub_fk_sub_situation_id !== null) {
                    this.setFieldValue('material_requisition_fk_sub_situation_id',itens.psm_sub_fk_sub_situation_id )
                }

                this.setFieldValue('material_requisition_priority', itens.psm_priority);
                // this.setFieldValue('material_requisition_deadline', itens.psm_deadline);
                this.setFieldValue('material_requisition_deadline', getNewDate);
                this.setFieldValue(`material_requisition_fk_${isCrud === 'commercials' ? 'situation' : 'dibi_situation'}_id`, itens[`${isSelectSituationPsm}`][`${isSelectSituationPsm}_id`]);
                console.log("Valor que vem no itens psm id", itens.psm_id)
                this.setFieldValue(`material_requisition_fk_psm_id`, itens.psm_id);
                // this.setFieldValue(`material_requisition_fk_psm_id`, itens[`${isSelectSituationPsm}`][`${isSelectSituationPsm}_id`]);
                this.setFieldValue('material_requisition_fk_company_id', itens.psm_fk_company_id)
                this.setField("material_requisition_logistics", {
                    gridAdd: false
                })

            })

            .finally(() => {
                setTimeout(() => {
                    this.setState('loadings', [])
                },1500)
            })
        }else{
            Notification.error({
                title: "error!",
                description: 'Psms divergentes para abertura',
                duration: 6500,
            });
        }
    
    }
}

