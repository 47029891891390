
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class ProductBoxIssueNfEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value == '') {
            // console.log("entrei aqui", value)
            // this.setFieldValue("psm_final_delivery_change", "‎")
            this.setFieldValue("product_box_issue_nf", "99/99/9999")
        }
    }
}

export class ProductBoxCollectDateEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value == '') {
            // console.log("entrei aqui", value)
            // this.setFieldValue("psm_final_delivery_change", "‎")
            this.setFieldValue("product_box_collect_date", "99/99/9999")
        }
    }
}


