import axios from "../../utilities/axios";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { Event } from "../Event";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export class ApprovalEvents extends Event {

    static async converterData(dataAmericana: string) {
        if (dataAmericana && dataAmericana.length > 8) {

            const partes = dataAmericana?.split('-');
            const splitData = partes[2]?.split('T');

            const mes = partes[1];
            const dia = splitData[0];
            const ano = partes[0];
            const dataBrasileira = `${dia}/${mes}/${ano}`;
            return dataBrasileira;
        }
    }

    public onLoad = async (value: any) => {
        var dadosPsm = this.globalProps.values;


        if (dadosPsm?.psm_approve_date) {
            const dataCorretaApprove = await ApprovalEvents.converterData(this.globalProps.values.psm_approve_date);
            this.setFieldValue("psm_approve_date", '')


            if (dataCorretaApprove !== null) {
                this.setFieldValue("psm_approve_date", dataCorretaApprove)
            }

        }

        if (dadosPsm?.psm_final_date_delivery) {
            const dataCorretaDelivery = await ApprovalEvents.converterData(this.globalProps.values.psm_final_date_delivery);
            this.setFieldValue("psm_final_date_delivery", '')


            if (dataCorretaDelivery !== null) {
                this.setFieldValue("psm_final_date_delivery", dataCorretaDelivery)
            }

        }

        if (dadosPsm?.psm_final_delivery_change) {

            const dataCorretaDeliveryChange = await ApprovalEvents.converterData(this.globalProps.values.psm_final_delivery_change);
            this.setFieldValue("psm_final_delivery_change", '')
            // console.log("dataCorretaDeliveryChange :>", dataCorretaDeliveryChange)

            if (dataCorretaDeliveryChange !== null) {
                this.setFieldValue("psm_final_delivery_change", dataCorretaDeliveryChange)
            }


        }


        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm')

        this.setState("loadings", [
            "psm_fk_hub",
            "psm_fk_macro_region_id",
            "psm_microrregiao",
            "psm_sector_commercial",
            "psm_commercial_cell",
            "psm_internal",
            "psm_external",
            "psm_analisys_hour_budget",
            "psm_email_send_nfs",
            "psm_billing_type"

        ]);


        axios.get(`/api/v1/people/${dadosPsm.psm_fk_person_id}`)
            .then((res) => {
                console.log({ dadosPsm });
                this.setFieldValue("psm_fk_hub", res.data.item.person_fk_macro_region_id);
                this.setFieldValue("psm_microrregiao", res.data.item.person_microregion);
                this.setFieldValue("psm_commercial_cell", res.data.item.person_contact_cellphone);
                // this.setFieldValue("psm_email_send_nfs", res.data.item.person_has_shipper);
                this.setFieldValue("psm_partial_billing", res.data.item.person_accept_income);
                this.setFieldValue("psm_receiving_limit_nfse", res.data.item.person_get_nfse);
                this.setFieldValue("psm_measuring_sheet_frs", res.data.item.person_use_frs);

                this.setFieldValue("psm_obs_accept_income", res.data.item.person_obs_accept_income);
                this.setFieldValue("psm_obs_receiving_limit", res.data.item.person_obs_get_nfse);
                this.setFieldValue("psm_obs_measuring_sheet", res.data.item.person_obs_use_frs);

                var usersSellers = res.data.item.person_sellers;

                for (let i in usersSellers) {
                    let person = usersSellers[i].seller

                    if (person.seller_is_extern == true) {
                        this.setFieldValue("psm_external", person.seller_is_extern == true ? person.seller_name : "Não existe vendedor Externo");
                        continue;
                    }
                }
                for (let i in usersSellers) {
                    let person = usersSellers[i].seller
                    this.setFieldValue("psm_internal", person.seller_is_extern == false ? person.seller_name : "Não Existe Vendedor Interno");
                    this.setFieldValue("psm_from_fk_seller_id", person.seller_id);
                    continue;
                }

            })
            .catch((e) => { })
            .finally(() => {
                this.setState("loadings", []);
            });

        this.setFieldValue("psm_purpose_product", 1)

        if ((dadosPsm.psm_warranty_text !== '<P></P>') || (dadosPsm.psm_warranty_text == null)) {
            this.setFieldValue("psm_warranty_text", "A MXDRIVE garante por três meses os serviços, as peças e componentes utilizados no serviço descrito e realizados em seu laboratório, havendo a necessidade de nova intervenção o mesmo deverá ser realizado no laboratório, ficando a MXDRIVE isenta de qualquer despesa com transporte, assistência técnica e outros custos. A garantia se aplica ao equipamento reparado isentando a MXDRIVE por danos e perdas causados a terceiros ou outros equipamentos.")
        }

        // this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS JÁ ESTÃO INCLUSOS.")
        // this.setFieldValue("psm_proposal_due_date", "10 DIAS")
        // this.setFieldValue("psm_from_fk_seller_id", { value: 33, label: "VENDEDOR" });
        this.setFieldValue("psm_datebase_cnpj", dadosPsm.psm_person_cnpj_cpf);
        this.setFieldValue("psm_database_citie", dadosPsm.psm_person_city);
        this.setFieldValue("psm_states_uf", dadosPsm.psm_person_state);
        this.setFieldValue("psm_analisys_hour_budget", dadosPsm.psm_analisys_hour);
        var budgetClient = dadosPsm.psm_clients_fk_person_id;
        this.globalProps.values.psm_clients_fk_person_id = budgetClient;
        if (budgetClient === null) {
            this.setFieldValue("psm_clients_fk_person_id", dadosPsm.psm_fk_person_id)
        }

        // this.setFieldValue("psm_fk_payment_term_id", dadosPsm.payment_term)

        //transportadora
        var shippingCompany = dadosPsm.psm_shipping_company_fk_person_id;
        this.globalProps.values.psm_carriers_fk_person_id = shippingCompany;
        if (shippingCompany === null) {
            this.setFieldValue("psm_carriers_fk_person_id", dadosPsm.psm_shipping_company_fk_person_id)
        }
        this.setFieldValue("psm_carriers_fk_person_id", dadosPsm.psm_carriers_fk_person_id)
        this.setFieldValue("psm_shipment_type", 2)

        this.setFieldValue("psm_fk_activity", dadosPsm.person?.person_fk_activity_sector_id)

        var valorHoraServico = dadosPsm.psm_hour_value
        if (valorHoraServico === null) {
            this.setFieldValue("psm_hour_value", 250)
        }

        var vendedorExterno = dadosPsm.seller?.seller_is_extern;
        if (vendedorExterno === false) {
            this.setFieldValue("psm_internal", dadosPsm.seller?.seller_name);
            this.setFieldValue("psm_external", null);
        } else {
            this.setFieldValue("psm_internal", null);
            this.setFieldValue("psm_external", dadosPsm.seller?.seller_name);
        }

        var tipoKeyAccount = dadosPsm.seller?.seller_type_of_seller;
        if (tipoKeyAccount === 'K') {
            this.setFieldValue("psm_keyacoount", dadosPsm.seller?.seller_name);
        }

        var potencyCurrent = dadosPsm.psm_potency;
        if (potencyCurrent !== null) {
            this.setFieldValue("psm_potency_current", dadosPsm.psm_potency)
        }
        if (potencyCurrent === null) {
            this.setFieldValue("psm_potency_current", dadosPsm.psm_current)
        }


        this.setFieldValue("psm_conclusion_hour_to_finish", dadosPsm.psm_conclusion_hours_finish)
        this.setFieldValue("psm_minimum_value", dadosPsm.psm_conclusion_hours_to_finish * 180)
        this.setFieldValue("psm_conclusion_warranty_period", dadosPsm.psm_warranty_period)
        this.setFieldValue("psm_conclusion_warranty_requested", dadosPsm.psm_warranty_requested)


        this.setFieldValue("psm_considered_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        this.setFieldValue("psm_final_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        this.setFieldValue("psm_total_product_service", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))

        const maskDate = function (value: string) {
            if (value.includes('/')) {
                value = value.split('/').join('')
            }
            let day = value.substring(0, 2);
            let month = value.substring(2, 4);
            let year = value.substring(4, 8);
            return `${day}-${month}-${year}`
        }

        await axios
            .get(`/api/v1/approvals/psms/${dadosPsm.psm_id}/get`)
            .then((res) => {
                if (dadosPsm.psm_purpose_product !== null) {
                    this.setFieldValue('psm_billing_type', dadosPsm.psm_purpose_product == 1 ?
                        'USO E CONSUMO' : dadosPsm.psm_purpose_product == 2 ? 'REVENDA' : 'INDUSTRIALIZAÇÃO');
                }

                try {

                } catch (e) { console.error("Error: ", e) }


                let dateEntrie = dayjs(maskDate(dadosPsm.psm_approve_date), 'DD/MM/YYYY').format('YYYY-MM-DD');
                let finalDate = dayjs(dateEntrie).add(dadosPsm.psm_deadline, 'day').format('DD/MM/YYYY');
                if ([undefined, null, ''].includes(dadosPsm.psm_final_date_delivery)) {

                    let getDate = function (dataInicial: any, deadline: any) {
                        let days = deadline
                        let ignoredDays = [6, 0]
                        let initialDate: any = dayjs(dataInicial, 'YYYY-MM-DD').format("MM-DD-YYYY")
                        do {
                            let getWeekDay = dayjs(initialDate).get("day")

                            if (!ignoredDays.includes(getWeekDay)) {
                                days = days - 1
                            }
                            initialDate = dayjs(initialDate).add(1, 'day')
                        }
                        while (days > 0);
                        return dayjs(initialDate).format('DD/MM/YYYY');
                    }


                    this.setFieldValue("psm_final_date_delivery", getDate(dateEntrie, dadosPsm.psm_deadline))
                }

                if (![null, undefined, ''].includes(dadosPsm.psm_final_date_delivery)) {
                    let dateNow = dayjs().format("MM-DD-YYYY");
                    let dateDelivery = maskDate(![null, undefined, ''].includes(dadosPsm.psm_final_date_delivery) ? dadosPsm.psm_final_date_delivery : finalDate);
                    dateDelivery = dayjs(dateDelivery, 'DD-MM-YYYY').format('MM-DD-YYYY')
                    let resultDiff = function () {
                        let diffDate = dayjs(dateDelivery, "MM-DD-YYYY").diff(dateNow, 'day');

                        if (diffDate == 0) {
                            return "Hoje é o dia da Entrega";
                        }
                        if (Math.sign(diffDate) == 1) {
                            return `Faltam ${dayjs(dateDelivery, "MM-DD-YYYY").diff(dateNow, 'day')} dias`
                        } else {
                            return `Passaram ${dayjs(dateDelivery, "MM-DD-YYYY").diff(dateNow, 'day')} dias`
                        }
                    }
                    this.setFieldValue("psm_lead_time", dadosPsm.psm_status_fk_situation_id !== 16 ? resultDiff() : 'PSM Finalizada')
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => { })
    }

    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {

    // }
}

export class ReprovingEvents extends Event {
    public onLoad = async (value: any) => {
        var dadosPsm = this.globalProps.values;

        this.setState("loadings", [
            "psm_fk_hub",
            "psm_fk_macro_region_id",
            "psm_microrregiao",
            "psm_sector_commercial",
            "psm_commercial_cell",
            "psm_internal",
            "psm_external",
            "psm_analisys_hour_budget",
            "psm_show_budget"
        ]);
        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm')

        axios.get(`/api/v1/people/${dadosPsm.psm_fk_person_id}`)
            .then((res) => {
                this.setFieldValue("psm_fk_hub", res.data.item.person_fk_macro_region_id);
                this.setFieldValue("psm_microrregiao", res.data.item.person_microregion);
                this.setFieldValue("psm_commercial_cell", res.data.item.person_contact_cellphone);
                this.setFieldValue("psm_email_send_nfs", res.data.item.person_email_nfs);

                // this.setFieldValue("psm_email_send_nfs", res.data.item.person_has_shipper);
                this.setFieldValue("psm_partial_billing", res.data.item.person_accept_income);
                this.setFieldValue("psm_receiving_limit_nfse", res.data.item.person_get_nfse);
                this.setFieldValue("psm_measuring_sheet_frs", res.data.item.person_use_frs);

                this.setFieldValue("psm_obs_accept_income", res.data.item.person_obs_accept_income);
                this.setFieldValue("psm_obs_receiving_limit", res.data.item.person_obs_get_nfse);
                this.setFieldValue("psm_obs_measuring_sheet", res.data.item.person_obs_use_frs);

                this.setFieldValue("psm_obs_accept_income", res.data.item.person_obs_accept_income);
                this.setFieldValue("psm_obs_receiving_limit", res.data.item.person_obs_get_nfse);
                this.setFieldValue("psm_obs_measuring_sheet", res.data.item.person_obs_use_frs);

                this.setFieldValue("psm_partial_billing", res.data.item.person_accept_income);
                this.setFieldValue("psm_receiving_limit_nfse", res.data.item.person_get_nfse);
                this.setFieldValue("psm_measuring_sheet_frs", res.data.item.person_use_frs);

                var usersSellers = res.data.item.person_sellers;

                for (let i in usersSellers) {
                    let person = usersSellers[i].seller

                    if (person.seller_is_extern == true) {
                        this.setFieldValue("psm_external", person.seller_is_extern == true ? person.seller_name : "Não existe vendedor Externo");
                        continue;
                    }
                }
                for (let i in usersSellers) {
                    let person = usersSellers[i].seller
                    this.setFieldValue("psm_internal", person.seller_is_extern == false ? person.seller_name : "Não Existe Vendedor Interno");
                    this.setFieldValue("psm_from_fk_seller_id", person.seller_id);
                    continue;
                }

            })
            .catch((e) => { })
            .finally(() => {
                this.setState("loadings", []);
            });

        axios.get(`/api/v1/psms/${dadosPsm.psm_id}`)
            .then((res) => {


                if (dadosPsm.psm_purpose_product !== null) {
                    this.setFieldValue('psm_billing_type', dadosPsm.psm_purpose_product == 1 ?
                        'USO E CONSUMO' : dadosPsm.psm_purpose_product == 2 ? 'REVENDA' : 'INDUSTRIALIZAÇÃO');
                }

                // this.setFieldValue("psm_responsible_technician", "")

            })

        this.setFieldValue("psm_purpose_product", 1)

        if ((dadosPsm.psm_warranty_text !== '<P></P>') || (dadosPsm.psm_warranty_text == null)) {
            this.setFieldValue("psm_warranty_text", "A MXDRIVE garante por três meses os serviços, as peças e componentes utilizados no serviço descrito e realizados em seu laboratório, havendo a necessidade de nova intervenção o mesmo deverá ser realizado no laboratório, ficando a MXDRIVE isenta de qualquer despesa com transporte, assistência técnica e outros custos. A garantia se aplica ao equipamento reparado isentando a MXDRIVE por danos e perdas causados a terceiros ou outros equipamentos.")
        }

        this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS JÁ ESTÃO INCLUSOS.")
        if (dadosPsm.psm_proposal_due_date == null) {
            this.setFieldValue("psm_proposal_due_date", "10 DIAS")
        }
        // this.setFieldValue("psm_from_fk_seller_id", { value: 33, label: "VENDEDOR" });
        this.setFieldValue("psm_datebase_cnpj", dadosPsm.psm_person_cnpj_cpf);
        this.setFieldValue("psm_database_citie", dadosPsm.psm_person_city);
        this.setFieldValue("psm_states_uf", dadosPsm.psm_person_state);
        this.setFieldValue("psm_analisys_hour_budget", dadosPsm.psm_analisys_hour);
        var budgetClient = dadosPsm.psm_clients_fk_person_id;
        this.globalProps.values.psm_clients_fk_person_id = budgetClient;
        if (budgetClient === null) {
            this.setFieldValue("psm_clients_fk_person_id", dadosPsm.psm_fk_person_id)
        }

        // this.setFieldValue("psm_fk_payment_term_id", dadosPsm.payment_term)

        //transportadora
        var shippingCompany = dadosPsm.psm_shipping_company_fk_person_id;
        this.globalProps.values.psm_carriers_fk_person_id = shippingCompany;
        if (shippingCompany === null) {
            this.setFieldValue("psm_carriers_fk_person_id", dadosPsm.psm_shipping_company_fk_person_id)
        }
        this.setFieldValue("psm_carriers_fk_person_id", dadosPsm.psm_carriers_fk_person_id)
        this.setFieldValue("psm_shipment_type", 2)

        this.setFieldValue("psm_fk_activity", dadosPsm.person?.person_fk_activity_sector_id)

        var valorHoraServico = dadosPsm.psm_hour_value
        if (valorHoraServico === null) {
            this.setFieldValue("psm_hour_value", 250)
        }

        var vendedorExterno = dadosPsm.seller?.seller_is_extern;
        if (vendedorExterno === false) {
            this.setFieldValue("psm_internal", dadosPsm.seller?.seller_name);
            this.setFieldValue("psm_external", null);
        } else {
            this.setFieldValue("psm_internal", null);
            this.setFieldValue("psm_external", dadosPsm.seller?.seller_name);
        }

        var tipoKeyAccount = dadosPsm.seller?.seller_type_of_seller;
        if (tipoKeyAccount === 'K') {
            this.setFieldValue("psm_keyacoount", dadosPsm.seller?.seller_name);
        }

        var potencyCurrent = dadosPsm.psm_potency;
        if (potencyCurrent !== null) {
            this.setFieldValue("psm_potency_current", dadosPsm.psm_potency)
        }
        if (potencyCurrent === null) {
            this.setFieldValue("psm_potency_current", dadosPsm.psm_current)
        }


        this.setFieldValue("psm_conclusion_hour_to_finish", dadosPsm.psm_conclusion_hours_finish)
        this.setFieldValue("psm_minimum_value", dadosPsm.psm_conclusion_hours_to_finish * 180)
        this.setFieldValue("psm_conclusion_warranty_period", dadosPsm.psm_warranty_period)
        this.setFieldValue("psm_conclusion_warranty_requested", dadosPsm.psm_warranty_requested)


        this.setFieldValue("psm_considered_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        this.setFieldValue("psm_final_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        this.setFieldValue("psm_total_product_service", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))

        let dateEntrie = dayjs(dadosPsm.psm_approve_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        var dataPrazo: any
        await axios
            .get(`/api/v1/approvals/psms/${dadosPsm.psm_id}/get`)
            .then((res) => {
                dataPrazo = res.data.items.psm_deadline
                if (dadosPsm.psm_purpose_product !== null) {
                    this.setFieldValue('psm_billing_type', dadosPsm.psm_purpose_product == 1 ?
                        'USO E CONSUMO' : dadosPsm.psm_purpose_product == 2 ? 'REVENDA' : 'INDUSTRIALIZAÇÃO');
                }

            })
            .finally(() => { })

        let finalDate = dayjs(dateEntrie).add(dataPrazo, 'day').format('DD/MM/YYYY');
        // this.setFieldValue("psm_final_date_delivery", finalDate)

    }

    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {

    // }
}