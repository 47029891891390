import _ from "lodash";
import axios from "../../utilities/axios";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { Event } from "../Event";
import dayjs from "dayjs";

export class BoxEvents extends Event {

    static async converterData(dataAmericana: string) {
        if (dataAmericana && dataAmericana.length > 8) {

            const partes = dataAmericana?.split('-');
            const splitData = partes[2]?.split('T');

            const mes = partes[1];
            const dia = splitData[0];
            const ano = partes[0];
            const dataBrasileira = `${dia}/${mes}/${ano}`;
            return dataBrasileira;
        }
        if (dataAmericana && dataAmericana.length == 8) {
            console.log("dataAmericana :>", dataAmericana)

            const mes = dataAmericana.substring(4, 6);
            const dia = dataAmericana.substring(6, 8);
            const ano = dataAmericana.substring(0, 4);
            const dataBrasileira = `${dia}/${mes}/${ano}`;
            return dataBrasileira;
        }
    }


    public onInit = () => {
        if (!this.globalProps.location.pathname.includes('/view')) {
            axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;
                    //console.log(subData);
                    if (subData) {
                        this.setFieldValue("box_user", subData.user.user_name);
                    }
                })
                .catch((error) => { })
                .finally(() => {
                    const loadings = [...this.globalState.loadings];
                    loadings.splice(loadings.indexOf("psm_user"));
                    this.setState({ loadings });
                });
            this.setFieldValue("box_date_romanian", dayjs().format("DD/MM/YYYY HH:mm"));
            this.setFieldValue("box_volume", 1);
            this.setFieldValue("box_fk_box_situation_id", 1);
        }
    }

    public onLoad = async () => {
        // this.setState("loadings",[
        //     "box_fk_box_situation_id"
        // ]);
        // if(this.globalProps.match.params?.id){
        //     if(this.globalProps.values?.box_fk_box_situation_id == null){
        //         const dataSituations:any = [
        //             {
        //                 currentPsm: 14,
        //                 currentBox: 1
        //             },
        //             {
        //                 currentPsm: 15,
        //                 currentBox: 2
        //             },
        //             {
        //                 currentPsm: 16,
        //                 currentBox: 3
        //             },
        //             {
        //                 currentPsm: 7,
        //                 currentBox: 1,
        //             },
        //             {
        //                 currentPsm: 8,
        //                 currentBox: 2
        //             },
        //             {
        //                 currentPsm: 9,
        //                 currentBox: 3
        //             }
        //         ]
        //         let boxInclusionSituation = this.globalProps.values.box_inclusions[0];
        //         await axios.get(`/api/v1/${boxInclusionSituation.box_inclusion_business_unity == 'LAB' ? 'commercials': 'dibis'}/` + boxInclusionSituation.box_inclusion_id_psm)
        //         .then((res) => {
        //             const requestIdSituation = boxInclusionSituation.box_inclusion_business_unity == 'LAB' ? res.data.item.psm_status_fk_situation_id : res.data.item.psm_status_fk_dibi_situation_id;
        //             const situationBox = _.find( dataSituations, { currentPsm: requestIdSituation})?.currentBox
        //             this.setFieldValue('box_fk_box_situation_id', situationBox )
        //         })
        //     }
        // }
 
        // console.log("Valor do this ::>", this)
        if (!this.globalProps.location.pathname.includes('/add')) {
            this.setFieldValue("box_user", this.globalProps.values?.box_user.toLowerCase())


            if (this.globalProps.values.box_issue_nf) {

                const dataCorreta = await BoxEvents.converterData(this.globalProps.values.box_issue_nf);
                this.setFieldValue("box_issue_nf", '')

                if (dataCorreta !== null) {
                    this.setFieldValue("box_issue_nf", dataCorreta)
                }
            }

            if (this.globalProps.values.box_collect_date) {

                const dataCorreta = await BoxEvents.converterData(this.globalProps.values.box_collect_date);
                this.setFieldValue("box_collect_date", '')

                if (dataCorreta !== null) {
                    this.setFieldValue("box_collect_date", dataCorreta)
                }
            }

        }
        requiredColumns(this.globalProps.values.box_fk_box_situation_id, this, 'box')
        // this.setState("loadings",[]);
    }

}
