import { toUpper } from "lodash";
import { Event } from "../Event";
import { Notification } from "rsuite";


export class CommercialEvents extends Event {


}

// psm_final_delivery_change
export class PsmFinalDeliveryChangeEvents extends Event {

    // static convertToTimestamp(dateString: string): any {
    //     if (dateString.length < 8) {
    //         throw new Error("Formato de data inválido. Use 'ddMMyyyy'.");
    //     }

    //     console.log("datestring :>", dateString)
    //     if (dateString){
    //         const day = parseInt(dateString.substring(0, 2), 10);
    //         const month = parseInt(dateString.substring(2, 4), 10) - 1;
    //         const year = parseInt(dateString.substring(4, 8), 10);

    //         const date = new Date(year, month, day);


    //         const formattedDate = date.toISOString().replace('T', ' ').substring(0, 23);

    //         const timezoneOffset = " -0300";

    //         return `${formattedDate}${timezoneOffset}`;
    //     }

    // }


    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        if (value == '') {
            // console.log("entrei aqui", value)
            // this.setFieldValue("psm_final_delivery_change", "‎")
            this.setFieldValue("psm_final_delivery_change", "99/99/9999")
        }

    }

}

export class PsmFinalDateDeliveryEvents extends Event {


    // static convertToTimestamp(dateString: string): any {
    //     if (dateString.length < 8) {
    //         throw new Error("Formato de data inválido. Use 'ddMMyyyy'.");
    //     }

    //     console.log("datestring 2:>", dateString)

    //     if(dateString){
    //         const day = parseInt(dateString.substring(0, 2), 10);
    //         const month = parseInt(dateString.substring(2, 4), 10) - 1;
    //         const year = parseInt(dateString.substring(4, 8), 10);

    //         const date = new Date(year, month, day);


    //         const formattedDate = date.toISOString().replace('T', ' ').substring(0, 23);

    //         const timezoneOffset = " -0300";

    //         return `${formattedDate}${timezoneOffset}`;
    //     }
    // }


    // public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

    //     console.log("valor do onChange :.", value)
    //     if(value){
    //         const timestamp = PsmFinalDateDeliveryEvents.convertToTimestamp(value);

    //         this.setFieldValue("psm_final_date_delivery", timestamp)
    //     }

    // }

}