import axios from "../../utilities/axios";
import { Event } from "../Event";
import dayjs from "dayjs";

export class PsmEquipmentCheckedEvents extends Event {
    static convertToTimestamp(dateString: string): any {
        if (dateString.length < 8) {
            throw new Error("Formato de data inválido. Use 'ddMMyyyy'.");
        }

        if (dateString) {
            const day = parseInt(dateString.substring(0, 2), 10);
            const month = parseInt(dateString.substring(2, 4), 10) - 1;
            const year = parseInt(dateString.substring(4, 8), 10);

            const date = new Date(year, month, day);


            const formattedDate = date.toISOString().replace('T', ' ').substring(0, 23);

            const timezoneOffset = " -0300";

            return `${formattedDate}${timezoneOffset}`;
        }
    }

    public onChange = async (value: any, setField: any, setState: any, values: string) => {

        // if (value) {
        //     const timestamp = PsmEquipmentCheckedEvents.convertToTimestamp(value);
        //     this.setFieldValue("psm_equipment_checked", timestamp)
        // }

    }
}