import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmCommercialFkPersonContactIdEvents extends Event {
    public onChange = (value: string, setField: any, setState: any) => {
        console.log('tipo ', typeof (value))
        if (value == null) {
            //console.log("Entrou")
            setState([
                "psm_sector_commercial",
                "psm_commercial_phone",
                "psm_commercial_email",
                "psm_commercial_branch",
                "psm_commercial_cell",
            ]);
            setField("psm_sector_commercial", "");
            setField("psm_commercial_phone", "");
            setField("psm_commercial_email", "");
            setField("psm_commercial_branch", "");
            setField("psm_commercial_cell", "");
        }
        // console.log('Event do campo de contato ::: ', { value })
        if (value) {
            setState("loadings", [
                "psm_sector_commercial",
                "psm_commercial_phone",
                "psm_commercial_email",
                "psm_commercial_branch",
                "psm_commercial_cell",
                "psm_commercial_fk_person_contact_id"
            ]);

            this.setFieldValue("psm_commercial_fk_person_contact_id", parseInt(value));

            axios
                .get("/api/v1/person-contacts/" + value)
                .then(function (res) {
                    var data = res.data;
                    // console.log("valor do data :>", data)
                    if (data) {
                        axios
                            .get("/api/v1/person-contacts/" + data.item.person_contact_id)
                            .then(function (res) {
                                var data = res.data;
                                if (data) {
                                    // console.log({ data });
                                    axios.get("/api/v1/sector/" + data.item.person_contact_fk_sector_id)
                                        .then(function (sector) {
                                            if(sector){
                                                setField("psm_sector_commercial", sector.data.item.sector_description)
                                            }
                                            // console.log("res", res)
                                        })
                                    setField("psm_phone_products", data.item.person_contact_phone);
                                    setField("psm_mail_products", data.item.person_contact_mail);
                                    setField("psm_cell_products", data.item.person_contact_cellphone);
                                    setField("psm_branch_products", data.item.person_contact_ramal);
                                    // setField("psm_contact_phone", data.item.person_district);
                                    // setField("estimete_contact_email", data.item.city.city_name);
                                }
                            })
                            .catch((e) => { })
                            .finally(() => {
                                setState("loadings", []);
                            });
                        if (data.item.sector) {
                            setField("psm_sector_commercial", data.item.sector.sector_description);
                        } else {
                            setField("psm_sector_commercial", 'NENHUM');
                        }
                        setField("psm_commercial_phone", data.item.person_contact_phone);
                        setField("psm_commercial_email", data.item.person_contact_mail);
                        setField("psm_commercial_branch", data.item.person_contact_ramal);
                        setField("psm_commercial_cell", data.item.person_contact_cellphone);

                        // setField("psm_contact_phone", data.item.person_district);
                        // setField("estimete_contact_email", data.item.city.city_name);
                    }
                })
                .catch((e) => { })
                .finally(() => {
                    setState("loadings", []);
                });
        }
    };
}
