import React from "react";
import { Table, Modal, Button, Loader, Panel, Notification, Alert, Checkbox, Whisper, Tooltip, Input } from "rsuite";
import axios from "../../../utilities/axios";
import { FilterableCell } from "../../customTable/FilterableCell";
import { SchemaToParsedCell } from "../../customTable/SchemaToParsedCell";
import Formalize from "../../Formalize";
import _ from "lodash";
import { InlineGrid } from "../InlineGrid";
import { SortableArray } from "../../sortableArray/main";
import { CustomExtensions } from "../../../custom/CustomExtensions";
import pluralize from "pluralize";
import { Grid } from "../Grid";
import { AxiosSelectPicker } from "../AxiosSelectPicker";
import FormalizeCustomDefects from "../../FormalizeCustomDefects";

const { Column, HeaderCell, Cell } = Table;
//config
const NomeDoCustom = 'psm_defects_services_optionals_custom';
const currentTableDeleteDefeito = "optionals_fault_phrases";
const currentTableDeleteSolucao = "optionals_service_performeds";

export class DefectsAndServicesCustomOptionals extends React.PureComponent<any, {}> {

    public state: any = {
        // GRID DEFEITO
        formDefeito_isLoading: true,
        formDefeito_isRead: false,
        formDefeito_isModalOpen: false,
        formDefeito_isSettingsModalOpen: false,
        formDefeito_isEdit: null,
        formDefeito_form: {},
        formDefeito_totalWidth: 0,
        formDefeito_hiddenRows: [],
        formDefeito_selectedValues: {},
        formDefeito_searchs: {},
        formDefeito_columnOrder: "[]", //JSON.parse(localStorage.getItem("column-order-" + this.props.name + "-" + this.props.globals.table) ?? "[]"),
        formDefeito_items: _.cloneDeep(this.props.values?.[NomeDoCustom]?.ihm_fault_phrases ?? []),
        formDefeito_isConfirmDeleteModal: false,

        //GRID SOLUCAO
        formSolucao_isLoading: true,
        formSolucao_isRead: false,
        formSolucao_isModalOpen: false,
        formSolucao_isSettingsModalOpen: false,
        formSolucao_isEdit: null,
        formSolucao_form: {},
        formSolucao_totalWidth: 0,
        formSolucao_hiddenRows: [],
        formSolucao_selectedValues: {},
        formSolucao_searchs: {},
        formSolucao_columnOrder: "[]", //JSON.parse(localStorage.getItem("column-order-" + this.props.name + "-" + this.props.globals.table) ?? "[]"),
        formSolucao_items: _.cloneDeep(this.props.values?.[NomeDoCustom]?.ihm_service_performeds ?? []),
        formSolucao_isConfirmDeleteModal: false,
    };

    private eventHandler: any | null = null;
    public elementRef: any = null;
    public cachedApiItems: any = {};

    onConfirmDeleteDefeitos = async (rowData: any, rowIndex: any, currentTable: any) => {
        //  const currentTable ='teste' //this.props.field['name'];
        // console.log("CLICOU",rowData,rowIndex,currentTable)
        axios.post("/api/v1/perm-delete", { isCustom: true, customName: NomeDoCustom, id: rowData['ihm_fault_phrases_id'], currentTable: currentTable }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado",
            });
        });

        var items = this.state.formDefeito_items;
        var merge = { ihm_fault_phrases: this.state.formDefeito_items, ihm_service_performeds: this.state.formSolucao_items }
        items.splice(rowIndex, 1);
        this.setState({ items, isLoading: true }, () => {
            if (this.props.onChange) {
                this.props.onChange(merge);
            }
            if (this.props.onDelete) {
                this.props.onDelete(merge);
            }
            this.setState({ isLoading: false, formDefeito_isLoading: false, formDefeito_isConfirmDeleteModal: false });
            this.setState({ tempDeleteItemID: null, tempDeleteItem: null, tempDeleteTable: null });
        });
    };

    onClickDeleteDefeito = async (rowIndex: any, rowData: any) => {
        // console.log({rowData})//delcio
        //console.log({rowData},{rowIndex})
        const currentTable = currentTableDeleteDefeito;
        this.setState({ formDefeito_isConfirmDeleteModal: true, tempDeleteItemID: rowIndex, tempDeleteItem: rowData, tempDeleteTable: currentTable });
    };


    onConfirmDeleteSolucao = async (rowData: any, rowIndex: any, currentTable: any) => {
        //  const currentTable ='teste' //this.props.field['name'];
        console.log("CLICOU", rowData, rowIndex, currentTable)
        axios.post("/api/v1/perm-delete", { isCustom: true, customName: NomeDoCustom, id: rowData['ihm_service_performed_id'], currentTable: currentTable }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado",
            });
        });

        var items = this.state.formSolucao_items;
        var merge = { ihm_fault_phrases: this.state.formDefeito_items, ihm_service_performeds: this.state.formSolucao_items }
        items.splice(rowIndex, 1);
        this.setState({ items, isLoading: true }, () => {
            if (this.props.onChange) {
                this.props.onChange(merge);
            }
            if (this.props.onDelete) {
                this.props.onDelete(merge);
            }
            this.setState({ isLoading: false, formSolucao_isLoading: false, formSolucao_isConfirmDeleteModal: false });
            this.setState({ tempDeleteItemID: null, tempDeleteItem: null, tempDeleteTable: null });
        });
    };

    onClickDeleteSolucao = async (rowIndex: any, rowData: any) => {
        // console.log({rowData})//delcio
        //console.log({rowData},{rowIndex})
        const currentTable = currentTableDeleteSolucao;
        this.setState({ formSolucao_isConfirmDeleteModal: true, tempDeleteItemID: rowIndex, tempDeleteItem: rowData, tempDeleteTable: currentTable });
    };



    renderActionsDefeito = (rowData: any, rowIndex: any) => {
        const extras = this.eventHandler?.getActions ? this.eventHandler?.getActions(rowData) : null;
        var idColumnName = this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id";
        return (
            <div style={{ display: "flex" }}>
                {/* {this.props.actions ? this.props.actions(rowData) : null}
                <i onClick={() => this.setState({ isRead: true, isModalOpen: true, selectedValues: rowData, isEdit: null })} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>
                {this.props.readOnly !== true && this.props.canEdit !== false && (
                    <i
                        onClick={() => this.setState({ isRead: false, isModalOpen: true, selectedValues: rowData, isEdit: rowIndex })}
                        className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                )} */}
                {this.props.readOnly !== true && this.props.canDelete !== false && (
                    <i onClick={() => this.onClickDeleteDefeito(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                )}
                {extras}
                <Modal show={this.state.formDefeito_isConfirmDeleteModal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro ? o registro não poderá ser restaurado!</>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} onClick={() => this.onConfirmDeleteDefeitos(this.state.tempDeleteItem, this.state.tempDeleteItemID, this.state.tempDeleteTable)} appearance="primary">
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ formDefeito_isConfirmDeleteModal: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* {JSON.stringify(this.eventHandler)} */}
            </div>
        );
    };

    renderActionsSolucao = (rowData: any, rowIndex: any) => {
        const extras = this.eventHandler?.getActions ? this.eventHandler?.getActions(rowData) : null;
        // var idColumnName = this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id";
        return (
            <div style={{ display: "flex" }}>
                {/* {this.props.actions ? this.props.actions(rowData) : null}
                <i onClick={() => this.setState({ isRead: true, isModalOpen: true, selectedValues: rowData, isEdit: null })} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>
                {this.props.readOnly !== true && this.props.canEdit !== false && (
                    <i
                        onClick={() => this.setState({ isRead: false, isModalOpen: true, selectedValues: rowData, isEdit: rowIndex })}
                        className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                )} */}
                {this.props.readOnly !== true && this.props.canDelete !== false && (
                    <i onClick={() => this.onClickDeleteSolucao(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                )}
                {extras}
                <Modal show={this.state.formSolucao_isConfirmDeleteModal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro ? o registro não poderá ser restaurado!</>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} onClick={() => this.onConfirmDeleteSolucao(this.state.tempDeleteItem, this.state.tempDeleteItemID, this.state.tempDeleteTable)} appearance="primary">
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ formSolucao_isConfirmDeleteModal: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* {JSON.stringify(this.eventHandler)} */}
            </div>
        );
    };

    openAddDefeito = () => {
        this.setState({ formDefeito_isRead: false, formDefeito_isModalOpen: true, formDefeito_isEdit: null, formDefeito_selectedValues: {} });
    };
    openAddSolucao = () => {
        this.setState({ formSolucao_isRead: false, formSolucao_isModalOpen: true, formSolucao_isEdit: null, formSolucao_selectedValues: {} });
    };

    componentDidMount() {
        this.elementRef = React.createRef();
        var api = "ihm_fault_phrases";
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ formDefeito_form: fields });
                }
            })
            .finally(() => {
                this.setState({ formDefeito_isLoading: false });
            });


        var api = "ihm_service_performeds";
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ formSolucao_form: fields });
                }
            })
            .finally(() => {
                this.setState({ formSolucao_isLoading: false });
            });
    }

    onSubmitDefeito = async (values: any) => {
        var objInserirOutroGrid: any;
        for (var fieldName in values) {
            var value = values[fieldName];
            var field = this.state.formDefeito_form[fieldName];
            if (field && field.name == "ihm_fault_phrase_fk_fault_phrase_id") {
                var url = field.api.url;
                try {
                    var result = await axios.get(field.api.url.split("/select").join("/" + value), { params: { cache: true } });
                    if (result && result.data && result.data.status === 200) {
                        // this.cachedApiItems[url][value] = result.data.item;
                        values = Object.assign(values, { defect_found_name: result.data.item["defect_found_name"] });
                        // values = ({values,defect_found_name:result.data.item['defect_found_name']})
                        //  console.log("O SELECT", result.data.item["defect_found_name"]);
                        //  values[field.displayLabel.split(".").shift()] = result.data.item;
                    }
                } catch (e) {
                    console.error(e);
                }

                // console.log({values})
            }
            if (field && field.name == "ihm_fault_phrase_fk_service_performed_id" && values.ihm_fault_phrase_fk_service_performed_id) {
                var url = field.api.url;
                try {
                    var result = await axios.get(field.api.url.split("/select").join("/" + value), { params: { cache: true } });
                    if (result && result.data && result.data.status === 200) {
                        // this.cachedApiItems[url][value] = result.data.item;
                        objInserirOutroGrid = {
                            ihm_fault_phrase_fk_service_performed_id: values.ihm_fault_phrase_fk_service_performed_id,
                            service_solution_name: result.data.item["service_solution_name"],
                            ihm_service_performed_executado: values.ihm_fault_phrase_executado,
                            ihm_service_performed_recommended: values.ihm_fault_phrase_recommended
                        }; //Mateus, adciona compnents.
                        // values = ({values,defect_found_name:result.data.item['defect_found_name']})
                        //  console.log("O SELECT", result.data.item["service_solution_name"]);
                        //  values[field.displayLabel.split(".").shift()] = result.data.item;
                    }
                } catch (e) {
                    console.error(e);
                }

                // console.log({values})
            }
        }
        //(this.cachedApiItems);

        this.setState(
            (oldState: any) => {
                var items = [...oldState?.formDefeito_items];
                // //(values, this.state.isEdit);
                // //(this.state.form);

                if (this.state.formDefeito_isEdit !== null) {
                    items[this.state.formDefeito_isEdit] = values;
                } else {
                    items.push(values);
                }
                if (values.ihm_fault_phrase_fk_service_performed_id) {
                    return { formDefeito_items: items, formSolucao_items: [...this.state.formSolucao_items, objInserirOutroGrid], formDefeito_isModalOpen: false };
                } else {
                    return { formDefeito_items: items, formDefeito_isModalOpen: false }
                }
            },
            () => {
                var mergeDados = { ihm_fault_phrases: this.state.formDefeito_items, ihm_service_performeds: this.state.formSolucao_items }
                if (this.props.onChange) {
                    this.props.onChange(mergeDados);
                    this.reOpenModal();
                }
                if (this.state.isEdit !== null) {
                    if (this.props.onEdit) {
                        this.props.onEdit(values, values.length);
                    }
                } else {
                    if (this.props.onAdd) {
                        this.props.onAdd(values, values.length);
                    }
                }
            },
        );
    };
    reOpenModal = () => {
        setTimeout(this.openAddDefeito, 1000);
    }
    onSubmitSolucao = async (values: any) => {
        for (var fieldName in values) {
            var value = values[fieldName];
            var field = this.state.formSolucao_form[fieldName];
            console.log({ field })
            if (field && field.name == "ihm_service_performed_fk_service_performed_id") {
                var url = field.api.url;
                try {
                    var result = await axios.get(field.api.url.split("/select").join("/" + value), { params: { cache: true } });
                    if (result && result.data && result.data.status === 200) {
                        // this.cachedApiItems[url][value] = result.data.item;
                        values = Object.assign(values, { service_solution_name: result.data.item["service_solution_name"] });
                        values = Object.assign(values, { ihm_fault_phrase_fk_service_performed_id: result.data.item["service_solution_id"] });
                        // values = ({values,defect_found_name:result.data.item['defect_found_name']})
                        //  console.log("O SELECT", result.data.item["defect_found_name"]);
                        //  values[field.displayLabel.split(".").shift()] = result.data.item;
                    }
                } catch (e) {
                    console.error(e);
                }

                // console.log({values})
            }

        }
        //(this.cachedApiItems);

        this.setState(
            (oldState: any) => {
                var items = [...oldState.formSolucao_items];
                // //(values, this.state.isEdit);
                // //(this.state.form);

                if (this.state.formSolucao_isEdit !== null) {
                    items[this.state.formSolucao_isEdit] = values;
                } else {
                    items.push(values);
                }
                return { formSolucao_items: items, formSolucao_isModalOpen: false };
            },
            () => {
                var mergeDados = { ihm_fault_phrases: this.state.formDefeito_items, ihm_service_performeds: this.state.formSolucao_items }
                if (this.props.onChange) {
                    this.props.onChange(mergeDados);
                }
                if (this.state.isEdit !== null) {
                    if (this.props.onEdit) {
                        this.props.onEdit(values, values.length);
                    }
                } else {
                    if (this.props.onAdd) {
                        this.props.onAdd(values, values.length);
                    }
                }
            },
        );
    };
    renderSubmitDefeito = (onSubmitEvent: any) => {
        // return null;
        return (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => {
                        sessionStorage.removeItem("currentParent");
                        sessionStorage.removeItem("currentParentIndex");
                        this.setState({ formDefeito_isModalOpen: false });
                    }}>
                    <i className="fas fa-fw fa-times mr-2"></i>
                    Cancelar
                </Button>
                {this.state.formDefeito_isEdit !== undefined && this.state.formDefeito_isRead === false && (
                    <Button
                        color="green"
                        onClick={() => {
                            sessionStorage.removeItem("currentParent");
                            sessionStorage.removeItem("currentParentIndex");
                            if (onSubmitEvent) {
                                onSubmitEvent();
                            }
                        }}>
                        <i className="fas fa-fw fa-save mr-2"></i>
                        Gravar
                    </Button>
                )}
            </div>
        );
    };

    renderSubmitSolucao = (onSubmitEvent: any) => {
        // return null;
        return (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => {
                        sessionStorage.removeItem("currentParent");
                        sessionStorage.removeItem("currentParentIndex");
                        this.setState({ formSolucao_isModalOpen: false });
                    }}>
                    <i className="fas fa-fw fa-times mr-2"></i>
                    Cancelar
                </Button>
                {this.state.formSolucao_isEdit !== undefined && this.state.formSolucao_isRead === false && (
                    <Button
                        color="green"
                        onClick={() => {
                            sessionStorage.removeItem("currentParent");
                            sessionStorage.removeItem("currentParentIndex");
                            if (onSubmitEvent) {
                                onSubmitEvent();
                            }
                        }}>
                        <i className="fas fa-fw fa-save mr-2"></i>
                        Gravar
                    </Button>
                )}
            </div>
        );
    };

    onChangeCheck(rowData: any, rowIndex: any, fieldNameType: any) {
        var valorAtual = this.props?.values[NomeDoCustom] // mudar para variavel NomeDoCustom
        if (valorAtual.ihm_service_performeds) {
            if (fieldNameType == "executado") {
                rowData.ihm_service_performed_executado = !rowData?.ihm_service_performed_executado

            }
            if (fieldNameType == "recomendado") {
                rowData.ihm_service_performed_recommended = !rowData?.ihm_service_performed_recommended

            }

            valorAtual.ihm_service_performeds[rowIndex] = rowData;

            this.setState(() => {
                this.props.onChange(valorAtual)
                if (this.state.isEdit !== null) {
                    if (this.props.onEdit) {
                        this.props.onEdit(valorAtual, valorAtual.length);
                    }
                } else {
                    if (this.props.onAdd) {
                        this.props.onAdd(valorAtual, valorAtual.length);
                    }
                }
            })

        }
    }

    render() {
        return (
            <>
                <div style={{ display: "inline-flex" }}>
                    {/*    <div style={{ flexDirection: "column", width: '40rem' }} ref={this.elementRef}> */}
                    <Panel bordered bodyFill style={{ padding: 8 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                            {this.props.compact !== true && <h4 dangerouslySetInnerHTML={{ __html: "Defeitos Encontrados" }}></h4>}
                            {/* {((this.props.values.psm_status_fk_situation_id == 5) && ( */}
                            <div>
                                <Button color="green" onClick={this.openAddDefeito}>
                                    <i className="fas fa-fw fa-plus mr-2"></i>
                                    Adicionar
                                </Button>
                            </div>
                            {/* ))} */}
                        </div>
                        <Modal overflow={false} size={"lg"} className="rs-modal-min" show={this.state.formDefeito_isModalOpen}>
                            {Object.keys(this.state.formDefeito_form).length > 0 && (

                                <FormalizeCustomDefects
                                    isModal={true}
                                    readOnly={false}
                                    parentValues={this.props.parentValues}
                                    parent={this.props.parent}
                                    values={_.cloneDeep(this.state.formDefeito_selectedValues)}
                                    onSubmit={this.onSubmitDefeito}
                                    submitButton={this.renderSubmitDefeito}
                                    form={this.state.formDefeito_form}
                                    history={this.props.history}
                                    name={"this.props.field.api"}
                                />

                            )}
                        </Modal>

                        {/* {JSON.stringify(this.state.formDefeito_items)} */}
                        <Table
                            headerHeight={45}
                            rowHeight={35}
                            className={this.props.compact ? "compact" : ""}
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                            loading={this.state.isLoading}
                            height={400}
                            data={_.orderBy(this.state.formDefeito_items, ['ihm_fault_phrases_id'], ['asc'])}>
                            <Column resizable width={350}>
                                <HeaderCell>Defeitos Encontrados</HeaderCell>
                                {/* <Cell dataKey="defect_found_name" className="link-group"></Cell> */}
                                <Cell dataKey="defect_found_name" className="link-group">
                                    {(rowData: any, rowIndex: any) => {
                                        return (
                                            <>
                                                {!this.state.isLoading && (
                                                    <Input
                                                        className="input"
                                                        size="md"
                                                        defaultValue={rowData.defect_found_name}
                                                        onChange={(e) => {
                                                            this.props.values.psm_defects_services_optionals_custom.ihm_fault_phrases[rowIndex].defect_found_name = e;
                                                        }}
                                                    />
                                                )}
                                            </>
                                        );
                                    }}
                                </Cell>
                            </Column>
                            <Column fixed={"right"} width={100}>
                                <HeaderCell>Ações</HeaderCell>
                                {/* {((this.props.values.psm_status_fk_situation_id == 5) && ( */}
                                <Cell className="link-group">
                                    {(rowData: any, rowIndex: any) => {
                                        return this.renderActionsDefeito(rowData, rowIndex);
                                    }}
                                </Cell>
                                {/* ))} */}
                            </Column>
                        </Table>
                    </Panel>
                    {/* </div> */}

                    <div style={{ margin: 2 }}></div>

                    {/** GRID SOLUCOES */}

                    {/* <div style={{ display: "inline-flex" }}>
                        <div style={{ flexDirection: "column", width: 'auto' }} ref={this.elementRef}> */}
                    <Panel bordered bodyFill style={{ padding: 8 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                            {this.props.compact !== true && <h4 dangerouslySetInnerHTML={{ __html: "Serviços a Serem Realizados" }}></h4>}
                            {/* {((this.props.values.psm_status_fk_situation_id == 5) && ( */}
                            <div>
                                <Button color="green" onClick={this.openAddSolucao}>
                                    <i className="fas fa-fw fa-plus mr-2"></i>
                                    Adicionar
                                </Button>
                            </div>
                            {/* ))} */}
                        </div>
                        <Modal overflow={false} size={"lg"} className="rs-modal-min" show={this.state.formSolucao_isModalOpen}>
                            {Object.keys(this.state.formSolucao_form).length > 0 && (
                                <Formalize
                                    isModal={true}
                                    readOnly={false}
                                    parentValues={this.props.parentValues}
                                    parent={this.props.parent}
                                    values={_.cloneDeep(this.state.formSolucao_selectedValues)}
                                    onSubmit={this.onSubmitSolucao}
                                    submitButton={this.renderSubmitSolucao}
                                    form={this.state.formSolucao_form}
                                    history={this.props.history}
                                    name={"this.props.field.api"}
                                />
                            )}
                        </Modal>
                        {/* {JSON.stringify(this.state.formSolucao_items)} */}
                        <Table
                            headerHeight={45}
                            rowHeight={35}
                            className={this.props.compact ? "compact" : ""}
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                            loading={this.state.isLoading}
                            height={400}
                            data={_.orderBy(this.state.formSolucao_items, ['ihm_service_performed_id'], ['asc'])}>
                            <Column resizable width={380}>
                                <HeaderCell>Serviços a Serem Realizados</HeaderCell>
                                {/* <Cell dataKey="service_solution_name" className="link-group"></Cell> */}
                                <Cell dataKey="service_solution_name" className="link-group">
                                    {(rowData: any, rowIndex: any) => {
                                        return (
                                            <>
                                                {!this.state.isLoading && (
                                                    <Input
                                                        className="input"
                                                        size="md"
                                                        defaultValue={rowData.service_solution_name}
                                                        onChange={(e) => {
                                                            this.props.values.psm_defects_services_optionals_custom.ihm_service_performeds[rowIndex].service_solution_name = e;
                                                        }}
                                                    />
                                                )}
                                            </>
                                        );
                                    }}
                                </Cell>
                            </Column>
                            <Column width={100}>
                                <HeaderCell>Executado</HeaderCell>
                                <Cell className="link-group">
                                    {(rowData: any, rowIndex: any) => {
                                        return (
                                            <Checkbox onChange={() => this.onChangeCheck(rowData, rowIndex, "executado")} defaultChecked={rowData?.ihm_service_performed_executado ? rowData?.ihm_service_performed_executado : false}></Checkbox>
                                        )
                                    }}
                                </Cell>
                            </Column>
                            <Column width={150}>
                                <HeaderCell>Recomendado</HeaderCell>
                                <Cell className="link-group">
                                    {(rowData: any, rowIndex: any) => {
                                        return (
                                            <Checkbox onChange={() => this.onChangeCheck(rowData, rowIndex, "recomendado")} defaultChecked={rowData?.ihm_service_performed_recommended ? rowData?.ihm_service_performed_recommended : false} ></Checkbox>
                                        )
                                    }}
                                </Cell>
                            </Column>
                            <Column fixed={"right"} width={100}>
                                <HeaderCell>Ações</HeaderCell>
                                {/* {((this.props.values.psm_status_fk_situation_id == 5) && ( */}
                                <Cell className="link-group">
                                    {(rowData: any, rowIndex: any) => {
                                        return this.renderActionsSolucao(rowData, rowIndex);
                                    }}
                                </Cell>
                                {/* ))} */}
                            </Column>
                        </Table>
                    </Panel>
                </div>
                {/* </div> */}
                {/* </div> */}
            </>
        );
    }
}
